// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useRouteElementId, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {RUpZhmlot: {hover: true}};

const cycleOrder = ["RUpZhmlot"];

const variantClassNames = {RUpZhmlot: "framer-v-1ko3gjg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "RUpZhmlot", title: b9_jZ7Z2s = "Rólunk", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RUpZhmlot", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const id1 = useRouteElementId("mOjfEdkQA", "pH3J1KNIf")

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4m90x", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Link href={{hash: id1, webPageId: "pH3J1KNIf"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1ko3gjg", className)} framer-6i4aez`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RUpZhmlot"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"RUpZhmlot-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Rólunk</motion.p></React.Fragment>} className={"framer-o935fd"} layoutDependency={layoutDependency} layoutId={"WL9ivN9gM"} style={{"--framer-paragraph-spacing": "0px", textShadow: "none"}} text={b9_jZ7Z2s} transformTemplate={transformTemplate} transition={transition} variants={{"RUpZhmlot-hover": {textShadow: "0px 0px 4px rgb(102, 120, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"RUpZhmlot-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"}>Rólunk</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4m90x [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4m90x * { box-sizing: border-box; }", ".framer-4m90x .framer-6i4aez { display: block; }", ".framer-4m90x .framer-1ko3gjg { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 51px; }", ".framer-4m90x .framer-o935fd { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-4m90x .framer-v-1ko3gjg .framer-1ko3gjg { cursor: pointer; }", ".framer-4m90x.framer-v-1ko3gjg.hover .framer-1ko3gjg { overflow: hidden; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 51
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fSb7W3Qha":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"b9_jZ7Z2s":"title"}
 */
const FramerHMPP52Uwi: React.ComponentType<Props> = withCSS(Component, css, "framer-4m90x") as typeof Component;
export default FramerHMPP52Uwi;

FramerHMPP52Uwi.displayName = "Rólunk";

FramerHMPP52Uwi.defaultProps = {height: 32, width: 51};

addPropertyControls(FramerHMPP52Uwi, {b9_jZ7Z2s: {defaultValue: "Rólunk", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerHMPP52Uwi, [...sharedStyle.fonts])